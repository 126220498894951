const projects = [
  {
    title: "ScannAR Shipment Management",
    description:
      "A shipment management application that allows users to determine the most efficient boxes for multiple items, track shipments and goods in real-time, and collect business data. Constructed a back-end server using Node for integration with both web and iOS applications. Created a ReactJS app using Material-UI framework for styling, and Redux for state management.",
    test_account: "Test account | Email: testscannar@outlook.com | Password: testscannar",
    tech: ["ReactJS", "MaterialUI", "Node", "Redux", "Firebase"],
    video: "https://www.youtube.com/watch?v=EmER0Xp2bBs",
    link: "https://scannarweb.herokuapp.com/",
    frontend_repo: "https://github.com/jtb36688/labs11-package-size-FE",
    backend_repo: "https://github.com/jtb36688/labs11-package-size-BE",
    thumb: "./thumbs/scannar.jpg"
  },
  {
    title: "Conway's Game of Life",
    description:
      "Made-from-scratch ReactJS implementation of Conway's Game of Life. Uses an array of 2500 items to keep track of life/death states of a 50x50 grid of cells. An algorithm is used create a new array each pass by calculating the amount of neighbors each cell has. Made use of React's async setState helper to allow for double-buffering per frame.",
    tech: [
      "ReactJS"
    ],
    link: "https://gifted-easley-3cf969.netlify.com/",
    frontend_repo: "https://github.com/jtb36688/gameoflife",
    backend_repo: "",
    thumb: "./thumbs/conways.jpg"
  },
  {
    title: "Employment Skills",
    description:
      "A ReactJS project that allows an admin to manage a list of workers, which can be viewed on a separate public page. Uses JSON web tokens for authentication, and allows one facility per admin account. Connects with a Node server to keep track of user, worker, and facility data.",
    tech: ["React", "Redux", "Bootstrap"],
    link: "https://competent-mcnulty-cd1977.netlify.com/",
    frontend_repo: "https://github.com/jtb36688/pskills",
    backend_repo: "",
    thumb: "./thumbs/workerskills.jpg"
  },
  {
    title: "Recipe Book",
    description:
      "Using NodeJS with Express Framework I demonstrate Migrating and Seeding a relational database. This project contains a SQLite database with 3 related tables. A separate table is used to represent associated records from two of the tables with a many-to-many pairing.",
    tech: ["Node, SQL"],
    link: "",
    frontend_repo: "",
    backend_repo:
      "https://github.com/jtb36688/db-recipe-book",
    thumb: "./thumbs/code.jpg"
  },
  
  
  {
    title: "Classroom Noise Controller",
    description:
      "Microphone-enabled activity app for teachers which encourages students to quiet down. Displays cute characters in the view until the noise level exceeds a specified threshhold, at which point the game ends. Connects with a Node server to keep track of game scores over a range of dates.",
    tech: ["ReactJS", "Bootstrap"],
    link: "https://clever-khorana-8e6231.netlify.com/",
    frontend_repo: "https://github.com/classroomnoisecontroller/classroomnoisecontroller-FE",
    backend_repo: "",
    thumb: "./thumbs/noisecontroller.jpg"
  },
  {
    title: "Gigapet Family Nutrition",
    description:
      "Created a Node server for a nutritional log app that allows users to keep track of a family's diet choices.  Works with a ReactJS client, with a calendar component that acquires data from the back-end across a range of specified dates.",
    test_account: "Test account | Login: homer | Password: simpson",
    tech: ["Node", "SQL"],
    frontend_repo: "",
    backend_repo: "https://github.com/jtb36688/gigapetserver",
    thumb: "./thumbs/code.jpg"
  }
];

export default projects;
