import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
  Card
} from "semantic-ui-react";
import projects from "./projectdata.js";
import ProjectCard from "./components/ProjectCard";
import ScrollMagic from "scrollmagic";
import "./reset.css"
import "./App.css";

const PrimaryButton = styled(Button)`
  &&& {
    color: black
    background: grey;
    margin: 10px;
  }
  
`;

const getWidth = () => {
  const isSSR = typeof window === "undefined";

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

const HomepageHeading = ({ mobile }) => (
  <Container className="HeaderBox" style={{
    border: "4px double black",
    borderRadius: "15px",
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    padding: "30px 0",
    marginTop: mobile ? "1em" : "4em",
  }} text>
    <Header
      as="h1"
      content="Jacob Bryan"
      style={{
        fontSize: mobile ? "3em" : "4em",
        fontWeight: "900",
        marginBottom: 0,
        // marginTop: mobile ? "1.5em" : "3em"
      }}
    />
    <Header
      as="h2"
      content="Web Developer - Atlanta, Georgia"
      style={{
        fontSize: mobile ? "1.5em" : "1.7em",
        fontWeight: "normal",
        margin: mobile ? "0.5em" : "1.5em"
      }}
    />
    {/* <p
      style={{
        fontSize: "1em",
        fontStyle: "italic",
        textShadow:
          "2px 5px 14px #000000, 2px 5px 14px #000000, 2px 5px 14px #000000"
      }}
    >
      "Never Give Up" <br /> -- Jacob
    </p> */}
    <PrimaryButton size="small" href="#projects">
      Check out my projects
      <Icon name="right arrow" />
    </PrimaryButton>
    <PrimaryButton
      size="small"
      rel="noopener noreferrer"
      target="_blank"
      href="https://docs.google.com/document/d/1ccED90Iz7eCCQ79_De4vKWSMWcEaYAKcFmf8a1QEAUY/edit?usp=sharing"
    >
      Download Resume
      <Icon name="right arrow" />
    </PrimaryButton>
    <PrimaryButton size="small" href="#contact">
    About Me
    <Icon name="right arrow" />
    </PrimaryButton>
  </Container>
);

HomepageHeading.propTypes = {
  mobile: PropTypes.bool
};

class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Menu
            id="home"
            fixed={fixed ? "top" : null}
            inverted={fixed}
            pointing={!fixed}
            secondary={!fixed}
            size="large"
          >
            <Container>
              <Menu.Item as="a" href="#home">
                Home
              </Menu.Item>
              <Menu.Item as="a" href="#projects">
                Projects
              </Menu.Item>
              <Menu.Item as="a" href="#contact">
                Contact Me
              </Menu.Item>
              <Menu.Item
                as="a"
                rel="noreferrer noopener"
                target="_blank"
                href="https://twitter.com/jbryview"
              >
                Twitter
              </Menu.Item>
              <Menu.Item
                as="a"
                rel="noreferrer noopener"
                target="_blank"
                href="https://github.com/jtb36688"
              >
                GitHub
              </Menu.Item>
              {/* <Menu.Item
                as="a"
                rel="noreferrer noopener"
                target="_blank"
                href="https://medium.com/@concertskyward"
              >
                Medium
              </Menu.Item> */}
              <Menu.Item
                as="a"
                rel="noreferrer noopener"
                target="_blank"
                href="https://www.linkedin.com/in/jbryanlink/"
              >
                LinkedIn
              </Menu.Item>
            </Container>
          </Menu>
          <Segment
            className="test"
            inverted
            textAlign="center"
            style={{
              minHeight: 500,
              margin: "0",
              padding: "1em 0em",
              background: "url('./basketballhorizon.jpg')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            vertical
          >
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node
};

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation="push"
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as="a" active>
            Home
          </Menu.Item>
          <Menu.Item as="a" href="#projects" onClick={this.handleSidebarHide}>
            Projects
          </Menu.Item>
          <Menu.Item as="a" href="#contact" onClick={this.handleSidebarHide}>
            Contact Me
          </Menu.Item>
          <Menu.Item
            as="a"
            rel="noreferrer noopener"
            target="_blank"
            href="https://twitter.com/jbryview"
            onClick={this.handleSidebarHide}
          >
            Twitter
          </Menu.Item>
          <Menu.Item
            as="a"
            rel="noreferrer noopener"
            target="_blank"
            href="https://github.com/jtb36688"
            onClick={this.handleSidebarHide}
          >
            GitHub
          </Menu.Item>
          {/* <Menu.Item
            as="a"
            rel="noreferrer noopener"
            target="_blank"
            href="https://medium.com/@concertskyward"
            onClick={this.handleSidebarHide}
          >
            Medium
          </Menu.Item> */}
          <Menu.Item
            as="a"
            rel="noreferrer noopener"
            target="_blank"
            href="https://www.linkedin.com/in/jbryanlink/"
            onClick={this.handleSidebarHide}
          >
            LinkedIn
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign="center"
            style={{
              minHeight: 350,
              padding: "1em 0em",
              background: "url('./basketballhorizon.jpg')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            }}
          >
            <Container>
              <Menu inverted pointing secondary size="large">
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node
};

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node
};

class App extends Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();
  }
  render() {
    return (
      <ResponsiveContainer>
        <Segment
          style={{ padding: "4em 0em", backgroundColor: "#dce2ea" }}
          vertical
        >
          <Grid container stackable verticalAlign="middle">
            {/* <Grid.Row>
              <Grid.Column width={8}>
                <Header as="h3" style={{ fontSize: "2em" }}>
                  Currently, my work involves helping businesses with the look,
                  feel and function of their online presence.
                </Header>
                <p style={{ fontSize: "1.33em" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent tortor orci, aliquet a condimentum eu, faucibus eu libero. Morbi fringilla eros vel purus consectetur, at rutrum purus finibus. Vivamus vulputate elementum volutpat. Aliquam commodo condimentum felis, vel dapibus enim blandit et. Aliquam erat volutpat. Donec aliquam a augue non fringilla. Curabitur aliquet sodales odio at pretium. Phasellus rhoncus arcu ac mi vehicula, ut semper sem tempor. Phasellus euismod iaculis dapibus. Aliquam ac ex nec neque tincidunt placerat. Vivamus in condimentum nunc, ut feugiat lacus. 
                </p>
                <Header as="h3" style={{ fontSize: "2em" }}>
                  Problem-Solver, Mentor and Leader
                </Header>
                <p style={{ fontSize: "1.33em" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent tortor orci, aliquet a condimentum eu, faucibus eu libero. Morbi fringilla eros vel purus consectetur, at rutrum purus finibus. Vivamus vulputate elementum volutpat. Aliquam commodo condimentum felis, vel dapibus enim blandit et. Aliquam erat volutpat. Donec aliquam a augue non fringilla. Curabitur aliquet sodales odio at pretium. Phasellus rhoncus arcu ac mi vehicula, ut semper sem tempor. Phasellus euismod iaculis dapibus. Aliquam ac ex nec neque tincidunt placerat. Vivamus in condimentum nunc, ut feugiat lacus. 
                </p>
              </Grid.Column>
              <Grid.Column floated="right" width={6}>
                <Image
                  bordered
                  rounded
                  size="large"
                  src="./thumbs/muriels_ol.png"
                />

                <Image
                  bordered
                  rounded
                  size="large"
                  src="./thumbs/caliber.png"
                />
                <Image
                  bordered
                  rounded
                  size="large"
                  src="./thumbs/vsc_thumb.png"
                />
                <Image
                  bordered
                  rounded
                  size="large"
                  src="./thumbs/melcover.png"
                />
              </Grid.Column>
            </Grid.Row> */}

            <Header as="h3" style={{ fontSize: "3em" }}>
              Projects
            </Header>

            <Grid.Row id="projects">
              <Grid.Column>
                <Card.Group itemsPerRow={2} centered stackable>
                  {projects.map(project => {
                    return (
                      <ProjectCard project={project} key={Math.random()} />
                    );
                  })}
                </Card.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment id="contact" style={{ padding: "4em 0em" }} vertical>
          
          {<div className="AboutMeContainer">
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
            

          <p style={{ fontSize: "2em"}}>Email me at:</p>
          <a style={{ fontSize: "2em", marginTop: 10}} href = "mailto: jacob@jbry.dev">jacob@jbry.dev</a>
          <div style= {{ width: "150px", margin: "30px 0px 30px 0px", borderRadius: "15px", border: "4px inset black"}}>
            <Image
              src="./thumbs/indexthumbs.png"
              style={{
                borderRadius: "11px",
                objectFit: "none",
                display: "inline-block",
               }}
            />
            </div>
          </div>
            <p className="AboutMeDescription">
            Hi, I'm Jake, a software engineer looking for a team to thrive with.  I've provided tech support for most of my life, often by installing computer hardware and
            maintaining PCs. I also had some exposure to Linux (Ubuntu) and iOS. I began studying Python in mid-2018, and then became intrigued to pursue a career
            in programming. I joined Lambda School, where I became proficient in using HTML/CSS, ReactJS, and Node to create useful web applications. <br/><br/>
            I have since been learning more about Python and computer science topics, such
            as big O notation and software algorithms. I'm very interested in the dev scene and would love to talk with you about your projects. If you're interested in learning about what 
            I could contribute to your team or more about Lambda School, don't hesitate to reach out to me.
            </p>
            
            
            {/* <Divider />
            <Image
              src="https://cdn-images-1.medium.com/max/2600/0*oTSgiGHowsFbWepS."
              size="huge"
            />
            <Header as="h3" style={{ fontSize: "2em" }}>
              Title Ipsum Here
            </Header>
            <p style={{ fontSize: "1.33em" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent tortor orci, aliquet a condimentum eu, faucibus eu libero. Morbi fringilla eros vel purus consectetur, at rutrum purus finibus. Vivamus vulputate elementum volutpat. Aliquam commodo condimentum felis, vel dapibus enim blandit et. Aliquam erat volutpat. Donec aliquam a augue non fringilla. Curabitur aliquet sodales odio at pretium. Phasellus rhoncus arcu ac mi vehicula, ut semper sem tempor. Phasellus euismod iaculis dapibus. Aliquam ac ex nec neque tincidunt placerat. Vivamus in condimentum nunc, ut feugiat lacus. 
            </p>
            <Button as="a" size="large">
              Read More
            </Button> */}
          </div>}
        </Segment>
        <Segment inverted vertical style={{ padding: "5em 0em" }}>
          <Container>
            <Grid divided inverted stackable>
              <Grid.Row>
                <Grid.Column width={3}>
                  <Header inverted as="h4" content="About" />
                  <List link inverted>
                    <List.Item
                      as="a"
                      rel="noreferrer noopener"
                      target="_blank"
                      href="https://twitter.com/jbryview"
                    >
                      Twitter
                    </List.Item>
                    <List.Item
                      as="a"
                      rel="noreferrer noopener"
                      target="_blank"
                      href="https://github.com/jtb36688"
                    >
                      GitHub
                    </List.Item>
                    {/* <List.Item
                      as="a"
                      rel="noreferrer noopener"
                      target="_blank"
                      href="https://medium.com/@concertskyward"
                    >
                      Medium
                    </List.Item> */}
                    <List.Item
                      as="a"
                      rel="noreferrer noopener"
                      target="_blank"
                      href="https://www.linkedin.com/in/jbryanlink/"
                    >
                      LinkedIn
                    </List.Item>
                  </List>
                </Grid.Column>

                <Grid.Column width={10}>
                  <Header as="h4" inverted />
                  <p style={{ fontStyle: "italic", fontSize: "1.33em" }}>
                    Jacob Bryan is currently studying full-time as a full-stack
                    web student with Lambda School. Jacob is available for web
                    development opportunities.
                  </p>
                  <p style={{ marginTop: "10px"}}>Favicon provided by Jo Szczepanska at <a href="https://www.toicon.com/">https://www.toicon.com/</a></p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </ResponsiveContainer>
    );
  }
}
export default App;
