import React from "react";
import { Card, Icon, Image } from "semantic-ui-react";

const ProjectCard = props => (
  <Card style={{ borderRadius: "0" }}>
    <Card.Content>
      {props.project.link ? (
        <a href={props.project.link}><Image src={props.project.thumb} style={{height: 300, width: "100%" , border: "4px double grey", borderRadius: "15px", padding: "5px"}}/></a>
      ) : (
        <a href={props.project.backend_repo}><Image src={props.project.thumb} style={{height: 300, width: "100%" , border: "4px double grey", borderRadius: "15px", padding: "5px"}}/></a>
      )}
      
      <Card.Header style={{ paddingTop: "20px" }}>
        {props.project.title}
      </Card.Header>
      <Card.Description>{props.project.description}</Card.Description>
      {props.project.test_account && (
      <Card.Content style={{fontSize: "14px", paddingTop: "7px", fontWeight: "700" }}>
        {props.project.test_account}
      </Card.Content>
    )}
    </Card.Content>
    {props.project.video ? (
      <Card.Content extra>
        <a href={props.project.video}>
          <Icon className="film" />
          Video Presentation on Youtube
        </a>
      </Card.Content>
    ) : null}
    {props.project.frontend_repo ? (
      <Card.Content extra>
        <a href={props.project.frontend_repo}>
          <Icon className="github square icon" />
          Front-End Repo on GitHub
        </a>
      </Card.Content>
    ) : null}
    {props.project.backend_repo ? (
      <Card.Content extra>
        <a href={props.project.backend_repo}>
          <Icon className="github square icon" />
          Back-End Repo on GitHub
        </a>
      </Card.Content>
    ) : null}
    {props.project.link ? (
      <Card.Content extra>
        <a rel="noopener noreferrer" target="_blank" href={props.project.link}>
          <Icon className="linkify icon" />
          View Deployed Project
        </a>
      </Card.Content>
    ) : null}
  </Card>
);

export default ProjectCard;
